import React from 'react';
import { makeStyles } from '@mui/styles';
import { Typography } from 'components';

import { CourseTableItem } from '../CoursesTable';

const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		alignItems: 'center',
		gap: theme.spacing(0.5)
	},
	text: {
		color: '#59748D'
	}
}));

type CourseVideosProps = Pick<CourseTableItem, 'numberOfVideos'>;

export const DesktopCourseVideos = ({ numberOfVideos }: CourseVideosProps) => {
	const classes = useStyles();

	return (
		<div className={classes.container}>
			<Typography
				localeId="catalog.mediathek.course.videos"
				localeValues={{ numberOfVideos }}
				variant="body2"
				className={classes.text}
			/>
		</div>
	);
};

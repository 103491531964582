import React from 'react';
import type { CourseTableItem } from '../CoursesTable';
import { makeStyles } from '@mui/styles';
import { useLocaleData } from 'common';

const useStyles = makeStyles((theme) => ({
	styledDatesContainer: {
		fontWeight: 600,
		fontSize: 14,
		lineHeight: '20px',
		whiteSpace: 'nowrap',

		[theme.breakpoints.down(380)]: {
			whiteSpace: 'normal'
		}
	},
	countryCode: {
		color: '#59748D',
		fontSize: 14,
		lineHeight: '20px',
		fontWeight: 700,
		marginRight: 'auto'
	}
}));

type CourseDatesContainerProps = Pick<
	CourseTableItem,
	'courseDates' | 'contentLocale' | 'modulesDuration'
> & {
	isOnDemandTable?: boolean;
};

export const CourseDatesContainer = ({
	courseDates,
	contentLocale,
	isOnDemandTable,
	modulesDuration
}: CourseDatesContainerProps) => {
	const classes = useStyles();
	const { domain } = useLocaleData();
	return (
		<>
			<span className={classes.styledDatesContainer}>
				{isOnDemandTable && modulesDuration ? modulesDuration : courseDates}
			</span>
			{contentLocale && domain === 'ch' && (
				<span className={classes.countryCode}>
					| {contentLocale.substring(0, 2).toUpperCase()}
				</span>
			)}
		</>
	);
};

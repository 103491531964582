import React from 'react';
import { CoursesTableProps, CourseTableItem } from '../CoursesTable';
import { makeStyles } from '@mui/styles';
import { Icon } from 'components';

const useStyles = makeStyles((theme) => ({
	courseParticipationContainer: {
		display: 'flex',
		alignItems: 'center',
		gap: theme.spacing(0.5),
		color: (props: { useLighterColour: boolean }) =>
			props.useLighterColour ? '#59748D' : theme.palette.primary.main
	},
	participationIcon: {
		width: theme.spacing(2),
		height: theme.spacing(2),
		color: (props: { useLighterColour: boolean }) =>
			props.useLighterColour ? '#59748D' : 'inherit'
	},
	participationInfo: {
		...theme.typography.textXSmall,
		letterSpacing: 'initial',
		lineHeight: 'auto'
	},
	onDemandIcon: {
		strokeColor: '#71889D',
		width: '24px',
		height: '24px',
		strokeWidth: '1px'
	}
}));

type CourseParticipationProps = Pick<
	CourseTableItem,
	'participationType' | 'participationCity' | 'participationOptionIconURL'
> &
	Pick<CoursesTableProps, 'variant'>;

export const MobileCourseParticipation = ({
	participationType,
	participationCity,
	participationOptionIconURL,
	variant
}: CourseParticipationProps) => {
	const classes = useStyles({ useLighterColour: variant === 'MULTIPRODUCT' });

	let participationText = participationType;
	if (variant === 'SINGLEPRODUCT' && participationCity) {
		participationText += ` (${participationCity})`;
	}

	if (variant === 'ONDEMAND' || variant === 'SYFAT') {
		return (
			<div className={classes.courseParticipationContainer}>
				<Icon icon="playCircle2" className={classes.onDemandIcon} />
				<div>OnDemand</div>
			</div>
		);
	}

	return (
		<div className={classes.courseParticipationContainer}>
			<img className={classes.participationIcon} src={participationOptionIconURL} />
			<span className={classes.participationInfo}>{participationText}</span>
		</div>
	);
};

import React, { useRef } from 'react';
import { CoursesTableProps, CourseTableItem } from '../CoursesTable';
import makeStyles from '@mui/styles/makeStyles';
import { CourseDatesContainer } from './CourseDatesContainer';
import { MobileCourseParticipation } from './MobileCourseParticipation';
import { CourseActionButtons } from '../shared/CourseActionButtons';
import { MobileCourseCredits } from '../mobile/MobileCourseCredits';
import { useIntl } from 'react-intl';
import { useIntersectionObserver } from 'common';
import { catalogTracking } from '../../../../../utils/segment';

const useStyles = makeStyles((theme) => ({
	card: {
		display: 'flex',
		flexDirection: 'column',
		color: theme.palette.primary.main,
		gap: theme.spacing(1),
		padding: theme.spacing(2),
		borderRadius: 6,
		border: '1px solid rgba(8, 8, 8, 0.12)'
	},
	subtitle: {
		fontWeight: 400,
		fontSize: 12
	},
	topRow: {
		display: 'flex',
		alignItems: 'center',
		gap: theme.spacing(1),
		color: theme.palette.primary.main
	},
	secondRow: {
		display: 'flex',
		alignItems: 'center',
		width: '100%',
		justifyContent: 'space-between'
	},
	divider: {
		width: 1,
		backgroundColor: 'rgba(0, 0, 0, 0.08)'
	}
}));

type SingleProductCardProps = Pick<CoursesTableProps, 'variant'> & {
	course: Pick<
		CourseTableItem,
		| 'courseDates'
		| 'participationType'
		| 'participationCity'
		| 'courseCredits'
		| 'participationOptionIconURL'
		| 'courseURL'
		| 'bookingURL'
		| 'contentLocale'
		| 'subtitle'
		| 'bookingButtonClassName'
		| 'detailsButtonClassName'
		| 'lightBackgroundColor'
		| 'contentId'
	>;
	onClick?: () => void;
	index: number;
};

/**
 * This component renders a course card in the mobile view when a single product is selected. When this is the case, we don't need to show the product name, product icon, and some other things in the card.
 */
export const SingleProductCard = (props: SingleProductCardProps) => {
	const classes = useStyles();
	const { locale } = useIntl();
	const { variant, course, onClick, index } = props;

	const {
		courseDates,
		participationType,
		participationCity,
		courseCredits,
		participationOptionIconURL,
		courseURL,
		contentLocale,
		bookingURL,
		subtitle,
		bookingButtonClassName = '',
		detailsButtonClassName = ''
	} = course;

	const containerRef = useRef<HTMLDivElement | null>(null);
	useIntersectionObserver(containerRef, {}, () => {
		catalogTracking.courseThumbnailViewed(course.contentId, { locale, index });
	});

	return (
		<article className={classes.card} ref={containerRef}>
			<div>
				<div className={classes.topRow}>
					<CourseDatesContainer courseDates={courseDates} contentLocale={contentLocale} />
				</div>
				{subtitle && <span className={classes.subtitle}>{subtitle}</span>}
			</div>
			<div className={classes.secondRow}>
				<MobileCourseParticipation
					participationType={participationType}
					participationCity={participationCity}
					participationOptionIconURL={participationOptionIconURL}
					variant={variant}
				/>
				<MobileCourseCredits courseCredits={courseCredits} variant="collapsed" />
			</div>
			<CourseActionButtons
				courseURL={courseURL}
				bookingURL={bookingURL}
				onClick={onClick}
				bookingButtonClassName={bookingButtonClassName}
				detailsButtonClassName={detailsButtonClassName}
			/>
		</article>
	);
};
